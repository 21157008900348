<template>
  <b-card id="planning-card" title="Entrades i sortides setmanals">
    <!-- CHECKIN CHECKOUT SELECTOR -->
    <b-row class="d-flex justify-content-between mb-2">
      <b-col cols="12" sm="auto" class="pt-25">
        <b-form-checkbox-group
          v-model="selectedBookingTypes"
          :options="checkinsCheckoutsOptions"
          value-field="value"
          text-field="name"
        />
      </b-col>
      <b-col
        cols="12"
        sm="auto"
        class="d-flex justify-content-center mt-2 mt-sm-0"
      >
        <b-button-group size="sm" class="w-100">
          <b-button
            v-b-tooltip.hover.bottom="'Setmana anterior'"
            variant="secondary"
            :disabled="loadingPlanning"
            @click="previousWeek"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Avui'"
            variant="secondary"
            :disabled="loadingPlanning"
            @click="currentWeek"
          >
            <feather-icon icon="CircleIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Setmana següent'"
            variant="secondary"
            :disabled="loadingPlanning"
            @click="nextWeek"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <!-- WEEKDAYS -->
    <b-row>
      <b-col cols="12">
        <b-nav justified class="mt-2 mb-3">
          <b-nav-item
            v-for="(day, index) in planningDays"
            :key="`planning-day-header-${index}`"
            :class="{
              'bg-primary shadow planning-day-active': sameDates(
                day,
                selectedDay
              ),
            }"
            :active="sameDates(day, selectedDay)"
            class="py-25"
            @click="toggleSelectedDay(day)"
          >
            <div class="text-uppercase planning-weekday">
              {{ getDayWeekday(day) }}
            </div>
            <div class="font-weight-bolder planning-day-number">
              {{ getDayNumber(day) }}
            </div>
            <div class="planning-month">
              {{ getDayMonth(day) }}
            </div>
            <div class="mt-25">
              <b-badge pill>
                {{ getBookingsCount(day) }}
              </b-badge>
            </div>
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
    <!-- BOOKINGS TABLE -->
    <b-row>
      <b-col cols="12">
        <bookings-planning-table
          :bookings="parsedFilteredplanning"
          :loading="loadingPlanning"
          :is-summary="isSummary"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BNav,
  BNavItem,
  BBadge,
  BFormCheckboxGroup,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import {
  formatDateObjectToDatabaseDate,
  formatDateObjectToDate,
} from "@/utils/formatters";
import {
  getBookingPendingPrice,
  getServiceName,
  notifyError,
  sameDates,
} from "@/utils/methods";
import BookingsPlanningTable from "@/views/bookings/planning/components/BookingsPlanningTable.vue";
import _debounce from "lodash/debounce";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BNav,
    BNavItem,
    BookingsPlanningTable,
    BBadge,
    BFormCheckboxGroup,
    BButtonGroup,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    isSummary: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedBookingTypes: ["CHECKINS"],
      selectedDay: new Date(new Date().setHours(0, 0, 0, 0)),
      selectedWeekStart: new Date(new Date().setHours(0, 0, 0, 0)),
      selectedDaysNumber: 7,
      selectedBooking: null,
      checkinsCheckoutsOptions: [
        { value: "CHECKINS", name: "Entrades" },
        { value: "CHECKOUTS", name: "Sortides" },
      ],
      planningDays: [],
    };
  },
  computed: {
    today() {
      let today = new Date().setHours(0, 0, 0, 0);
      today = new Date(today);
      return today;
    },
    loadingPlanning() {
      return this.$store.getters["bookings/loadingPlanning"];
    },
    checkinsPlanning() {
      return this.$store.getters["bookings/checkinsPlanning"];
    },
    checkoutsPlanning() {
      return this.$store.getters["bookings/checkoutsPlanning"];
    },
    planning() {
      if (this.loadingPlanning) return [];

      // Check for booking duplicates inside an array
      function unique(array) {
        const a = array.concat();
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < a.length; ++i) {
          // eslint-disable-next-line no-plusplus
          for (let j = i + 1; j < a.length; ++j) {
            // eslint-disable-next-line no-plusplus
            if (a[i].localizator === a[j].localizator) a.splice(j--, 1);
          }
        }
        return a;
      }

      return unique(this.checkinsPlanning.concat(this.checkoutsPlanning));
    },
    filteredPlanning() {
      if (this.planning.length === 0) return [];

      return this.planning.filter((booking) => {
        if (this.checkinsSelected && this.checkoutsSelected) {
          return (
            sameDates(this.selectedDay, new Date(booking.checkin)) ||
            sameDates(this.selectedDay, new Date(booking.checkout))
          );
        }
        if (this.checkinsSelected) {
          return sameDates(this.selectedDay, new Date(booking.checkin));
        }
        if (this.checkoutsSelected) {
          return sameDates(this.selectedDay, new Date(booking.checkout));
        }
        return false;
      });
    },
    parsedFilteredplanning() {
      if (this.filteredPlanning.length === 0) {
        return null;
      }

      return this.filteredPlanning.map((booking) => {
        const thirdPartyServices =
          booking.services
            ?.filter((bookingService) => {
              return ["3RD_PARTY_SERVICE", "ACCOMMODATION_EXTRA"].includes(
                bookingService.service?.type
              );
            })
            .map((bookingService) => ({
              code: bookingService.service?.code || null,
              description:
                getServiceName(bookingService.service, this.$i18n.locale) ||
                null,
            })) || [];

        return {
          uuid: booking.uuid || this.$t("No definit"),
          inOrOut:
            this.getCheckinCheckoutStatus(booking) || this.$t("No definit"),
          checkInOutTime:
            this.getCheckinCheckoutStatus(booking) === "CHECKIN"
              ? booking.details?.accommodationArrivalTime ||
                this.$t("No definit")
              : booking.details?.accommodationDepartureTime ||
                this.$t("No definit"),
          date: new Date(booking.date) || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || null,
          client: booking.client?.fullName || this.$t("No definit"),
          clientUuid: booking.client?.uuid || null,
          guests: this.bookingGuests(booking) || this.$t("No definit"),
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          accommodationUuid: booking.accommodation?.uuid || null,
          checkin: new Date(booking.checkin) || this.$t("No definit"),
          checkinTime:
            booking.details?.accommodationArrivalTime || this.$t("No definit"),
          checkout: new Date(booking.checkout) || this.$t("No definit"),
          checkoutTime:
            booking.details?.accommodationDepartureTime ||
            this.$t("No definit"),
          babyChairs: this.bookingBabyChairs(booking),
          babyCots: this.bookingBabyCots(booking),
          specialRequests: !!booking.specialRequestsNumber,
          services: thirdPartyServices,
          pending: this.hasPendingBookingPayment(booking),
          pendingSecurityDeposit: this.hasPendingSecurityDeposit(booking),
          pendingServices: this.hasPendingServices(booking),
          clientInstructions: this.hasSentClientInstructions(booking.logs),
          ownerInstructions: this.hasSentOwnerInformation(booking.logs),
          highlighted: booking.highlighted || false,
          cleaningProvided: this.hasCleaning(booking),
          cleaningCompleted: this.hasCleaningCompleted(booking),
          emailReminder: booking.stats?.ownerEmailCheckinReminderSent || false,
          whatsAppReminder: this.hasWhatsAppReminderSent(booking),
          bookingStatsUuid: booking?.stats?.uuid || null,
        };
      });
    },
    checkinsSelected() {
      return (
        this.selectedBookingTypes.findIndex((type) => type === "CHECKINS") !==
        -1
      );
    },
    checkoutsSelected() {
      return (
        this.selectedBookingTypes.findIndex((type) => type === "CHECKOUTS") !==
        -1
      );
    },
  },
  created() {
    this.setPlanningDays();
    this.fetchPlanning(this);
  },
  methods: {
    getDayMonth(date) {
      const formatting = { month: "short" };
      return formatDateObjectToDate(date, this.$i18n.locale, formatting);
    },
    getDayWeekday(date) {
      const formatting = { weekday: "long" };
      return formatDateObjectToDate(date, this.$i18n.locale, formatting);
    },
    getDayNumber(date) {
      const formatting = { day: "numeric" };
      return formatDateObjectToDate(date, this.$i18n.locale, formatting);
    },
    sameDates(date1, date2) {
      return sameDates(date1, date2);
    },
    toggleSelectedDay(day) {
      this.selectedDay = day;
    },
    toggleSelectedBookingType(selectedType) {
      const foundTypeIndex = this.selectedBookingTypes.findIndex(
        (type) => type === selectedType
      );
      if (foundTypeIndex !== -1) {
        // Selected type is already selected, we delete it form the selected types.
        this.selectedBookingTypes.splice(foundTypeIndex, 1);
      } else {
        // Selected type is not selected, we add it to the selected types.
        this.selectedBookingTypes.push(selectedType);
      }
    },
    fetchPlanning: _debounce((innerThis) => {
      innerThis.$store
        .dispatch("bookings/fetchPlanning", {
          days: innerThis.selectedDaysNumber,
          startDate: formatDateObjectToDatabaseDate(
            innerThis.selectedWeekStart
          ),
        })
        .catch(() => {
          notifyError(
            innerThis.$t("errors.fetchBooking.title"),
            innerThis.$t("errors.fetchBooking.description")
          );
        });
    }, 1000),
    getBookingsCount(day) {
      if (this.planning.length === 0) return 0;

      let count = 0;
      let checkinsCount = 0;
      let checkoutsCount = 0;

      this.planning.forEach((booking) => {
        if (sameDates(new Date(booking.checkin), day)) {
          checkinsCount += 1;
        } else if (sameDates(new Date(booking.checkout), day)) {
          checkoutsCount += 1;
        }
      });

      if (this.checkinsSelected) count += checkinsCount;
      if (this.checkoutsSelected) count += checkoutsCount;

      return `${count}`;
    },
    getCheckinCheckoutStatus(booking) {
      if (!booking) return null;
      if (sameDates(this.selectedDay, new Date(booking.checkin)))
        return "CHECKIN";
      if (sameDates(this.selectedDay, new Date(booking.checkout)))
        return "CHECKOUT";
      return null;
    },
    hasPendingBookingPayment(booking) {
      if (!booking) return false;
      return getBookingPendingPrice(booking) > 0;
    },
    hasPendingSecurityDeposit(booking) {
      if (!booking || booking.managedSecurityDeposit) return false;

      const securityDeposit = booking.services.find(
        (s) => s.service.code === "SECURITY_DEPOSIT"
      );
      if (!securityDeposit || !securityDeposit.chargable) return false;

      const securityDepositPrice = securityDeposit.pvpPrice || null;
      const securityDepositPaidPrice = securityDeposit.clientPayments.reduce(
        (acc, payment) => {
          if (
            ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
              payment.status
            )
          )
            return acc + (payment.pvpAmount || 0);
          return acc;
        },
        0
      );
      if (securityDepositPaidPrice < securityDepositPrice) return true;

      return false;
    },
    hasPendingServices(booking) {
      if (!booking) return false;

      const filteredServices = booking.services.filter(
        (s) => s.service.code !== "SECURITY_DEPOSIT"
      );
      if (!filteredServices.length) return false;

      let pendingServicesCount = 0;

      filteredServices.forEach((bookingService) => {
        if (!bookingService.chargable) return;
        if (!bookingService.pvpPrice) return;
        const servicePaidPrice = bookingService.clientPayments.reduce(
          (acc, p) =>
            ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
              p.status
            )
              ? acc + p.pvpAmount
              : acc,
          0
        );
        if (servicePaidPrice < bookingService.pvpPrice)
          pendingServicesCount += 1;
      });

      return pendingServicesCount > 0;
    },
    hasCleaning(booking) {
      if (!booking?.contract) {
        return null;
      }
      return !!booking.contract.cleaningProvided || false;
    },
    hasCleaningCompleted(booking) {
      if (!booking?.stats) {
        return null;
      }
      return !!booking.stats.cleaningCompleted || false;
    },
    hasWhatsAppReminderSent(booking) {
      if (!booking?.stats) {
        return null;
      }
      const { stats } = booking;
      const {
        ownerWhatsAppCheckinReminderSent,
        ownerWhatsAppCheckinReminderSentError,
      } = stats || {};
      return (
        (ownerWhatsAppCheckinReminderSent &&
          !ownerWhatsAppCheckinReminderSentError) ||
        false
      );
    },
    hasLogType(logs, type) {
      return logs?.some((log) => log.type === type) || false;
    },
    hasSentClientInstructions(logs) {
      return this.hasLogType(logs, "CLIENT_CHECKIN_INSTRUCTIONS_SENT");
    },
    hasSentOwnerInformation(logs) {
      return this.hasLogType(logs, "OWNER_CHECKIN_INFORMATION_SENT");
    },
    bookingBabyChairs(booking) {
      if (!booking || !booking.details || booking.details.babyChairs == null)
        return "-";
      return booking.details.babyChairs;
    },
    bookingBabyCots(booking) {
      if (!booking || !booking.details || booking.details.babyCots == null)
        return "-";
      return booking.details.babyCots;
    },
    bookingGuests(booking) {
      if (!booking) return null;
      const guests = [];
      guests.push(booking.adults || 0);
      guests.push(booking.children || 0);
      guests.push(booking.babies || 0);
      return guests.join("-");
    },
    setPlanningDays() {
      this.planningDays = [];

      const firstDay = new Date(this.selectedWeekStart);
      firstDay.setDate(firstDay.getDate() - 1);
      this.planningDays.push(firstDay);

      for (let i = 0; i < this.selectedDaysNumber; i += 1) {
        const day = new Date(this.selectedWeekStart);
        day.setDate(day.getDate() + i);
        this.planningDays.push(day);
      }
    },
    nextWeek() {
      this.selectedWeekStart.setDate(this.selectedWeekStart.getDate() + 7);
      this.selectedDay.setDate(this.selectedDay.getDate() + 7);
      this.setPlanningDays();
      this.fetchPlanning(this);
    },
    previousWeek() {
      this.selectedWeekStart.setDate(this.selectedWeekStart.getDate() - 7);
      this.selectedDay.setDate(this.selectedDay.getDate() - 7);
      this.setPlanningDays();
      this.fetchPlanning(this);
    },
    currentWeek() {
      this.selectedWeekStart = new Date(new Date().setHours(0, 0, 0, 0));
      this.selectedDay = new Date(new Date().setHours(0, 0, 0, 0));
      this.setPlanningDays();
      this.fetchPlanning(this);
    },
  },
};
</script>

<style lang="scss">
#planning-card {
  .planning-day-active a {
    color: white;
  }
  .planning-weekday {
    font-size: 10px;
  }
  .planning-month {
    font-size: 12px;
  }
  .planning-day-number {
    font-size: 20px;
    color: #666;
  }
}
</style>
