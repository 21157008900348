<template>
  <span>
    <b-spinner v-if="loading" class="align-middle" small />
    <b-badge
      v-else
      :pill="pill"
      :variant="variant"
      @click.stop="onClick"
      :class="classes"
      v-b-tooltip.hover.top="tooltipText"
    >
      {{ text }}
    </b-badge>
  </span>
</template>

<script>
import { BBadge, VBTooltip, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      localCleaningCompleted: false,
    };
  },
  props: {
    bookingStatsUuid: {
      type: String,
      default: null,
    },
    cleaningProvided: {
      type: Boolean,
      default: false,
    },
    cleaningCompleted: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: null,
    },
    pill: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    this.localCleaningCompleted = this.cleaningCompleted;
  },
  computed: {
    variant() {
      if (!this.cleaningProvided) {
        return "secondary";
      } else {
        return this.localCleaningCompleted ? "success" : "danger";
      }
    },
    text() {
      if (!this.cleaningProvided) {
        return this.$t("Propietari");
      } else {
        return this.localCleaningCompleted
          ? this.$t("Completada")
          : this.$t("Pendent");
      }
    },
    classes() {
      if (!this.cleaningProvided) {
        return [];
      }

      return ["cursor-pointer"];
    },
    tooltipText() {
      if (this.cleaningProvided) {
        return "Clica per canviar l'estat";
      } else {
        return "El propietari gestiona la neteja";
      }
    },
  },
  methods: {
    onClick() {
      if (!this.cleaningProvided) {
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("booking/updateStats", {
          uuid: this.bookingStatsUuid,
          cleaningCompleted: !this.localCleaningCompleted,
        })
        .then(() => {
          this.localCleaningCompleted = !this.localCleaningCompleted;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
